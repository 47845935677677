import React from "react"
import "../styles/o-nas.scss"
import _navigation from "../components/_navigation"
import Footer from "../components/footer"
import Lekarze from "../components/lekarze"
import ImageGallery from "react-image-gallery"
import zdjecie from "../assets/sylwia3.jpg"
import zdjecie1 from "../assets/9-2.jpg"
import zdjecie2 from "../assets/5-2.jpg"
import zdjecie3 from "../assets/2-2.jpg"
import zdjecie4 from "../assets/8.jpg"
import Filozofia from "../components/filozofia"
const ONas = () => {
  const images = [
    {
      original: `${zdjecie1}`,
      thumbnail: `${zdjecie1}`,
    },
    {
      original: `${zdjecie2}`,
      thumbnail: `${zdjecie2}`,
    },
    {
      original: `${zdjecie3}`,
      thumbnail: `${zdjecie3}`,
    },
    {
      original: `${zdjecie4}`,
      thumbnail: `${zdjecie4}`,
    },
  ]
  return (
    <div>
      <_navigation />
      <section className="hero hero-promocje-background">
        <div className="hero-body has-text-centered">
          <div className="container">
            <h1 className="title promocje-tytul">O nas</h1>
          </div>
        </div>
      </section>
      <section className="columns">
        <blockquote className="blockquote column is-centered has-text-centered">
          <p className="o-nas-powstanie ">Centrum Dermatologiczne Laser - Medica powstało w 2004 roku.</p>
        </blockquote>
      </section>
      <section className="columns">
        <div className="column is-hidden-desktop-only is-hidden-mobile is-hidden-tablet-only is-offset-2-widescreen is-2-widescreen is-offset-2-fullhd">
          <figure className="image">
            <img src={zdjecie} className="is-rounded" alt="zdjecie sylwi" />
          </figure>
        </div>
        <div className="o-nas-flex-center column is-offset-2-desktop is-8-desktop is-5-fullhd is-offset-1-tablet is-10-tablet is-offset-1-mobile is-10-mobile is-offset-0-widescreen is-5-widescreen">
          <p className="opis-o-nas">
            Od samego początku staramy się pracować na najnowocześniejszych technologiach laserowych, najlepszych (i
            najbezpieczniejszych) materiałach zabiegowych oraz ekskluzywnych kosmetykach używanych w trakcie zabiegów
            kosmetycznych. I niezmiennie inwestujemy w nowy sprzęt i w wiedzę. Mimo sporego już doświadczenia w zakresie
            proponowanych zabiegów, cały zespół lekarzy i kosmetologów stale podnosi swoje kwalifikacje, uczestnicząc w
            różnego rodzaju kursach i konferencjach poświęconych dermatologii estetycznej, dermatochirurgii i kosmetyce.{" "}
            <br />
            <br />
            Zapraszamy do zapoznania się z pełną ofertą Centrum, z pewnością każdy z Państwa znajdzie tu stosowny zabieg
            dla swojej skóry. Szanowni Państwo! Piękna, zadbana a przede wszystkim zdrowa skóra: tego Wam życzymy.
          </p>
          <p className="sylwia-podpis">Sylwia Farfał-Kałucka</p>
        </div>
      </section>
      <section style={{ height: "auto" }} className="">
        <Filozofia
          title="Nasze gabinety"
          opis="W naszych nowocześnie wyposażonych gabinetach z pewnością poczujecie się Państwo komfortowo"
        />
      </section>
      <div className="columns is-centered">
        <ImageGallery className="column is-offset-5-desktop is-4-mobile" items={images} />
      </div>
      <Lekarze onas={true} />
      <Footer />
    </div>
  )
}
export default ONas
